.modalOverlay {
  padding: 20px;

  background-color: #0000002f;

  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    position: fixed;
    margin: auto;
    padding: 0 20px 10px;

    width: calc(100% - 40px);
    border-radius: 25px;
    background: radial-gradient(
        51.44% 28.81% at 49.09% 115.08%,
        rgb(51, 241, 174) -20%,
        rgb(24, 32, 38) 90%
      ),
      rgba(28, 56, 74);

    .closeBtn {
      position: absolute;
      right: 9px;
      top: 3.5px;

      background: none;
      border: none;

      cursor: pointer;
    }

    .modalTopBar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      background: radial-gradient(
          51.44% 100.81% at 50.09% -25.08%,
          rgb(51, 241, 174) -20%,
          rgb(24, 32, 38) 90%
        ),
        rgba(28, 56, 74);

      .modalTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 85%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #eaffea;
      }
    }

    .modalContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .cominsoon{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        line-height: 85%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #eaffea;
        padding: 40px;
  }


      .values {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
      }

      .fieldBlock {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;

        margin-top: 4px;
      }

      .labelBlock {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .label {
        display: flex;
        align-items: center;
        gap: 2px;
        padding: 0 16px;

        font-weight: 300;
        font-size: 14px;
        line-height: 121%;
        letter-spacing: -0.04em;
        text-align: center;
        color: #eaffea;
      }

      .availability {
        display: flex;
        gap: 2px;
        padding: 0 16px;

        font-weight: 300;
        font-size: 10px;
        line-height: 1;
        letter-spacing: -0.05em;
        text-align: center;
        color: #eaffea;
        align-items: flex-end;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 1;
          letter-spacing: -0.04em;
          text-align: center;
          color: #eaffea;
        }
      }

      .correlation {
        display: flex;
        justify-content: space-between;
        gap: 23px;

        width: 100%;
      }

      .amount,
      .balanceBefore,
      .balanceAfter {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 42px 0 rgba(51, 241, 174, 0.5);

        height: 32px;
        width: 100%;
        border: 0.5px solid #3be5a8;
        border-radius: 25px;

        background: rgba(11, 12, 13, 0.3);

        font-weight: 400;
        font-size: 14px;
        line-height: 121%;
        letter-spacing: -0.04em;
        text-align: center;
        color: #eaffea;

        overflow: hidden;

        input {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
        }
      }

      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 159px;
        height: 30px;

        font-weight: 400;
        font-size: 18px;
        line-height: 94%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #eaffea;
        background: rgba(11, 12, 13, 0.3);

        border: 1px solid #3be5a8;
        border-radius: 25px;
        border: 1px solid #182025;
        border-radius: 25px;
        // box-shadow: 0 0 5px 0 #bbddf3;
        outline: 1px solid #3be5a8;
        overflow: hidden;

        cursor: pointer;
      }
    }
  }
}
