* {
  box-sizing: border-box;
}

.plusButton,
.removeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
}

.backBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #235548;

  width: 60px;
  height: 30px;

  border: 1px solid #182025;
  border-radius: 25px;
  box-shadow: 0 0 5px 0 #3be5a8;
  outline: 1px solid #c7ffc6;
  overflow: hidden;

  cursor: pointer;

  .customBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #c7ffc6;
    border: 1px solid #1c384a;
    border-radius: 25px;

    overflow: hidden;
  }
}

.walletBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;

  .customBorder {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 10px;
    overflow: hidden;
  }

  #ton-connect-button {
    button {
      background-color: #3be5a8;
    }
  }
}

.soldout{
  position: relative;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 3;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #FFFFFF;
    outline: 1px solid #FFFFFF;
    overflow: hidden;
    border: none;
    border-radius: 9px;
    background: #494A4A;
    min-width: 74px;
}
.buyButton {
  position: relative;

  z-index: 3;

  font-weight: 500;
  font-size: 12px;

  letter-spacing: -0.04em;
  text-align: center;
  color: #c7ffc6;

  outline: 1px solid #3be5a8;
  overflow: hidden;

  border: none;
  border-radius: 9px;
  box-shadow: 0 0 12px 0 #3be5a8, 0 0 4px 0 #3be5a8;

  background: radial-gradient(
    135.59% 144.61% at -15.19% 102.54%,
    #1c4a47 0%,
    #1c4a47 100%
  );

  cursor: pointer;

  .customBorder {
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 100%;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #1c384a;
    border-radius: 9px;
    overflow: hidden;
  }
}
