.navigation_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: width 0.3s ease-in-out;
    position: relative;
    background: #182025;
    padding: 5px;
    border-radius: 15px;
    overflow: hidden;

    svg{
        margin-top: -6px;
    }

    .text_container{
        display: flex;
        align-items: center;
        background-color: #182025;
        border-radius: 20px;
        text-align: center;
        width: 100%;
    }

    .text{
        font-weight: 600;
        font-size: 1rem;
    }
    .subtext{
        font-size: 0.7rem;
        width: 100%;    
    } 
    .text_container_info{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        gap: 5px;
        z-index: 1;
        width: 100%;
    }
   .shadow_top {
        width: 100%;
        height: 27px;
        border-radius: 50%;
        position: absolute;
        top: -50%;
        z-index: 0;
        opacity: 0.3;
    }
    .shadow_bottom{
        width: 100%;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        bottom: -51%;
        z-index: 0;
        opacity: 0.3;
    }      
    
}

.coming_soon_container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.text_coming_glow {
    background: radial-gradient(rgb(45 131 180 / 16%), rgb(255 255 255 / 0%));
    width: 100%;
    height: 40px;
    position: absolute;
    z-index: 0;
    border-radius: 30%;
}

.text_coming{
    color: #BBDDF3;
    text-align: center;
    font-size: 12px;
    border-radius: 50%;
}

.block-game{
    border: 1px solid #3BE5A8;
}
.start-mining-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #BBF3DF;
}
.custom-button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #000; 
  }
  
  
  .custom-button .text {
    font-size: 16px;
    color: #000;
  }
  
  .ton-connect-button {
    background: none !important;
    border: none !important;
    padding: 0 !important;
  }