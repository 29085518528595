* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.closeBtn{
  
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1d4349;
    width: 40px;
    height: 22px;
    flex-shrink: 0;
    border: 1px solid #182025;
    border-radius: 25px;
    outline: 1px solid #e53b3b !important;
    overflow: hidden;
    cursor: pointer;

}
.completed{
  background: #1C384A;
  border: 1px solid #339DD9;
  border-radius: 10px;
  padding: 4px;
  width: 40px;
}

.close{
  display: flex;
    justify-content: center;
    align-items: center;
    background: #491D1D;
    width: 40px;
    height: 22px;
    flex-shrink: 0;
    border: 1px solid #182025;
    border-radius: 25px;
    outline: 1px solid #FF2727;
    overflow: hidden;
    cursor: pointer;
  }

.coming_soon_market {
  border: 1px solid #008eff;
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  color: #000000;
  font-weight: 700;
  font-size: 1.4rem;
  background: radial-gradient(rgb(0 171 255 / 0%), rgb(0 142 255));
  font-size: 3vh;
  position: absolute;
  width: 95%;
}
.block {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid #3bb2e5;
  border-radius: 25px;
  background-color: rgba(28, 56, 74, 0.66);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 0px 1px 0;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;

    .updateBtn{
      background: #1C384A;
      border: 1px solid #339DD9;
      border-radius: 10px;
      padding: 4px;
      width: 40px;
      
      img{
        width: 70%;
      }
    }

    .orderTable {
      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;

      .tableHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        width: 100%;
        padding: 8px 2px;
        background: linear-gradient(to right, #1B3B49 0%, #1B3B49 20%, #1B3B49 36%, #1B3B49 60%, #1B3B49 90%, #1B3B49 100%);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .empty {
          width: 40px;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .buyerHeader,
        .amountHeader,
        .rateHeader,
        .priceHeader {
          display: flex;
          justify-content: center;
          width: 100%;

          font-weight: 400;
          font-size: 14px;
          line-height: 121%;
          letter-spacing: -0.04em;
          text-align: center;
          color: #c6e7ff;

          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
      }

      .tableContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .tableContent .tableCard {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        gap: 5px;
        padding: 0 1px;
        width: 100%;
    }
    
    .info { 
      display: flex;
      width: 100%;
      align-items: center;
      background: rgba(71, 137, 166, 0.9);
      border-radius: 5px;
      flex-direction: column;

    }

    .time {
      width: 17%;
      padding: 2px;
      border-radius: 4px;
  
      &.dark {
          background: rgba(17, 33, 40, 0.9);
      }
  
      &.light {
          background: rgba(71, 137, 166, 0.9);
      }
  }

    .black { 
      display: flex;
      width: 100%;
      align-items: center;
      background: rgba(17, 33, 40, 0.9);
      border-radius: 5px;
      flex-direction: column;

    }
    
    .statusContainer{
      display: flex;
      width: 80%;
      align-items: center;
      justify-content: space-between;
      padding: 2px;
      background: rgba(71, 137, 166, 0.9);
      border-radius: 4px;

      .status{
        width: 25%;
      }
      .pending{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        gap: 10%;
        padding-right: 5%;
        
      }

      
    }
    .statusContainerBlack{
      display: flex;
      width: 80%;
      align-items: center;
      justify-content: space-between;
      padding: 2px;
      background: rgba(17, 33, 40, 0.9);
      border-radius: 4px;

      .status{
        width: 25%;
      }
      .pending{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        gap: 10%;
        padding-right: 5%;
        
      }

      
    }
    
    .divider{
      background: linear-gradient(90deg, #3bb2e500 0%, #3BB2E5 30%, #3BB2E5 70%, #ffffff00 100%);
      width: 100%;
      height: 1px;
      margin: 3px 0;
    }

    .tableContent .tableCard .buyButton,
    .tableContent .tableCard .closeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1d4349;
        width: 40px;
        height: 22px;
        flex-shrink: 0;
        border: 1px solid #182025;
        border-radius: 25px;
        outline: 1px solid #3be5c6;
        overflow: hidden;
        cursor: pointer;
    }
    
    .tableContent .tableCard .customBorder {
        display: flex;
        // justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    
    .tableContent .tableCard .customBorder span {
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        letter-spacing: -0.04em;
        text-align: center;
        color: #c6ffea;
        width: 25%;
    }
    
    .tableContent .tableCard >span >div {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 5px 5px;
      border: none;
      font-weight: 300;
      font-size: 12px;
      line-height: 142%;
      letter-spacing: -0.04em;
      text-align: center;
      color: #c6e7ff;
      justify-content: space-between;
    }
    
    .tableContent .tableCard .buyer  {
        overflow: hidden;
        width: 20%;
    }
    
    }
  }
}
.comment{
  background: #1C384A;
    border: 1px solid #339DD9;
    border-radius: 10px;
    padding: 4px;
    width: 40px;
    color: #c6e7ff;
    font-size: 9px;
}