.block {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100%;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 106%;
    letter-spacing: -0.03em;
    text-align: center;
    color: #fff;
  }
  .bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    width: 100%;

    .legendLeft {
      display: flex;
      justify-content: center;
      align-items: center;

      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      color: #ffd0c6;
      background: linear-gradient(90deg, transparent, #141a1f);
      height: 30px;
      width: 75px;
      flex-shrink: 0;

      font-weight: 500;
      font-size: 18px;
      line-height: 94%;
      letter-spacing: -0.03em;
      text-align: center;
    }

    .legendRight {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      color: #ffd0c6;

      background: linear-gradient(-90deg, transparent, #141a1f);
      height: 30px;
      width: 75px;
      flex-shrink: 0;

      font-weight: 500;
      font-size: 18px;
      line-height: 94%;
      letter-spacing: -0.03em;
      text-align: center;
    }

    .progress {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 18px;
      width: 100%;

      border-radius: 25px;
      padding: 3px;

      background-color: #141a1f;

      .progressFill {
        width: 0%;
        height: 100%;
        background-color: #ae4f4f;

        background: linear-gradient(-90deg, #ae4f4f, rgba(131, 72, 77, 0));

        border-radius: 25px;
      }
    }
  }
}
