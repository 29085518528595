.miner {
  width: 100vw;
  height: 100vh;
  background-image: url("https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Backgr/Miner.webp");
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.miner_block {
  display: flex;
  flex-direction: column;
  background: #182025;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #3be5a8;
  justify-content: space-between;
  padding: 5px 5px 0 5px;

  .miner_container {
    background: rgba(29, 157, 111, 0.4);
    z-index: 1;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 20px;
    width: calc(100% + 5px);
  }

  .subtext {
    position: absolute;
    font-size: 1.5rem;
  }

  img {
    opacity: 0.3;
    border-radius: 20px;
    min-height: 100px;
    display: flex;
    justify-content: center;
  }
  .text {
    color: #c7ffc6;
  }
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  justify-content: center;
  height: 100%;
  margin: 0 5px;
  padding-bottom: 90px;
  overflow-x: hidden;
  overflow-y: auto;
}

.footer {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
}

.ue {
  font-size: 1rem;
}

@media screen and (max-height: 700px) {
  .miner_block {
    img {
      max-height: 80px;
    }
  }
}
