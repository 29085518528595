.cardList {
  display: flex;
  flex-direction: column;
  gap: 14px;

  padding: 10px 9px;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
