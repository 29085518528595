.repairBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 20px;

  letter-spacing: -0.03em;
  text-align: center;

  border-radius: 16px;
  border: 1px solid #182025;
  outline: 1px solid #3bb2e5;
  padding: 7px 10px 8px 10px;
  width: 154px;
  height: 41px;

  background-color: #1c384a;
  color: #fff;

  cursor: pointer;
}

.backBtn {
  background: #bbddf3;

  width: 24px;
  height: 24px;

  border: 1px solid #182025;
  border-radius: 100%;

  outline: 1px solid #339dd9;
  overflow: hidden;

  cursor: pointer;

  .customBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #1c384a;
    border-radius: 100%;
    overflow: hidden;
  }
}
