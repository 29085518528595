.plusButton,
.removeButton {
  display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    flex-direction: column;
    border: 2px solid #C7FFC6;
    border-radius: 50%;
    padding: 15px;
    color: #C7FFC6;

}

