* {
  box-sizing: border-box;
}

.slot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: calc(174 / 160 * 41.8vw);
  height: calc(160 / 174 * 41.8vw);
  border-radius: 16px;
  border: 1px solid #3be5a8;

  overflow: hidden;
  position: relative;

  flex-shrink: 0;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 142%;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    text-align: center;
    color: #c7ffc6;
  }

  .removeButton {
    position: absolute;
    top: 3px;
    right: 6px;
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    padding: 14px 6px;

    width: 100%;
    height: calc(128 / 174 * 41.8vw);

    border-radius: 16px;
    overflow: hidden;
    // background-color: aqua;

    position: relative;

    ::before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      z-index: -1;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20%,
        rgba(137, 202, 178, 0.3) 100%
      );
    }

    ::after {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      z-index: -1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20%,
        rgba(12, 66, 46, 0.3) 100%
      );
    }

    .addButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      ::before {
        content: "";
        left: 0;
        top: 0;
        position: absolute;

        width: 100%;
        height: 100%;
        opacity: 0.9;
        z-index: -1;
        background: rgb(0, 0, 0, 20%);
      }

      ::after {
        content: "";
        left: 0;
        top: 0;
        position: absolute;

        width: 100%;
        height: 100%;
        opacity: 0.9;
        z-index: -1;
        background: rgb(29, 157, 111, 20%);
      }
    }

    .backgroundImageFull {
      position: absolute;
      margin: 0 auto;
      width: 100%;
      object-fit: fill;
      bottom: -15%;
      left: 0;
      z-index: -1;
    }

    .backgroundImageEmpty {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    .descriptionItem {
      font-weight: 700;
      font-size: 7px;

      letter-spacing: -0.07em;
      text-align: left;
      color: #eaffea;

      .value {
        font-weight: 700;
        font-size: 12px;

        letter-spacing: -0.04em;
      }

      .unit {
        font-weight: 500;
      }
    }
  }
}
