.card {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;

  padding: 1px;

  border: 1px solid #3be5a8;
  border-radius: 16px;
  // background-color: rgb(24, 32, 37);

  .border {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 3px 4px;
    background: radial-gradient(
        51.44% 28.81% at 49.09% -5.08%,
        rgba(51, 241, 174, 0.55) -20%,
        rgb(24, 32, 38) 90%
      ),
      rgba(28, 56, 74);
    border-radius: 16px;
  }
  .topBar {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 28px;
    width: 100%;
    color: #c7ffc6;

    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.5px;
    text-transform: uppercase;
  }

  .body {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 84px;
    width: 100%;

    z-index: 1;

    border-radius: 16px;
    overflow: hidden;

    .modalButton {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 2px;

      width: 88px;
      height: 17px;

      color: #eaffea;

      text-align: center;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px; /* 141.667% */
      letter-spacing: -0.5px;
      background: #182025;

      border: 1px solid transparent;
      border-radius: 25px;
      // box-shadow: 0 0 5px 0 #bbddf3;
      outline: 1px solid #3be5a8;
      overflow: hidden;

      cursor: pointer;
      z-index: 999;
    }

    .content {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      width: 100%;
      height: 100%;

      z-index: 99;

      .value {
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 1px;
        color: #eaffea;

        text-align: center;

        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px; /* 85% */
        letter-spacing: -0.5px;
        .unit {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .backgroundImage {
      position: absolute;
      width: 100%;
      height: 100%;

      bottom: 0;
      left: 0;

      margin: auto;
      z-index: 2;

      &::after {
        position: absolute;
        content: "";
        // background: rgba(29, 157, 111, 0.4);
        background: radial-gradient(
            599.44% 78.81% at 49.09% 100.08%,
            rgba(0, 0, 0, 0.4) 10%,
            rgba(0, 0, 0, 0.1) 90%
          ),
          rgba(13, 126, 85, 0.4);
        z-index: 3;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
