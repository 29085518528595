$baseColor: #3bb2e5;
$textColor: #c6e7ff;
$borderColor: #3bb2e5;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: relative;
  padding: 0;

  width: 100%;

  border-radius: 25px;
  border: 1px solid $borderColor;

  background-color: rgba(28, 56, 74, 0.66);

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 57px;

    font-weight: 700;
    font-size: 34px;

    letter-spacing: -0.01em;
    text-align: center;
    color: $textColor;
  }

  .backBtn {
    position: absolute;
    left: 9px;
    top: 7px;
    z-index: 3;
  }

  .walletBtn {
    position: absolute;
    right: 9px;
    top: 7px;
    z-index: 3;
  }

  .bgImage {
    position: absolute;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    z-index: 0;

    img {
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0.25;
      background: radial-gradient(
        58.49% 58.49% at 58.49% 50%,
        #12a5f9 41%,
        #3bb2e5 100%
      );
    }
  }

  .topBar {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 100%;

    .title {
      position: absolute;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;

      color: $textColor;

      z-index: 20;
    }

    .value {
      font-size: 23px;
      font-weight: 700;
      color: #fff;
      z-index: 20;
    }

    .backgroundTrapezoid {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }

  .bottomBar {
    position: relative;

    z-index: 1;

    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
  }
}
