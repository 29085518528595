* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.friends {
  width: 100vw;
  height: 100vh;
  background-image: url("./02-w-Miner.png");
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 13px;
  overflow: hidden;

  padding: 10px;
  &::after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    top: 0;
    opacity: 0.2;
    background: radial-gradient(
      58.49% 58.49% at 58.49% 50%,
      #12a5f9 41%,
      #3bb2e5 100%
    );
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 10px;
  }
}
