* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;

  color: #c6e7ff;

  z-index: 20;
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px solid #3bb2e5;
  border-radius: 25px;
  background-color: rgba(28, 56, 74, 0.66);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    gap: 5px;

    .message {
      font-weight: 700;
      font-size: 24px;
      line-height: 125%;
      letter-spacing: -0.02em;
      text-align: center;
      color: #c6e7ff;
      width: 266px;
    }
  }

  .topBar {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    position: static;
    
    .title {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;

      color: #c6e7ff;

      z-index: 20;
    }

    .value {
      font-size: 23px;
      font-weight: 700;
      color: #fff;
      z-index: 20;
    }

    .backgroundTrapezoid {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.refferal{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #c6e7ff;
}
.flex{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.contentBonus{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.grid {
  display: flex;
  background-image: url("./back.png");
  background-repeat: no-repeat;
  width: 100%;
  border: 1px solid #3BB2E5;
  border-radius: 40px;
  background-color: rgba(24, 32, 37, 0.5);
  justify-content: space-between;
  padding-right: 25px;
  }

  .image{
    padding: 3px;
  }

  .titleBonus { 
    color: #C6E7FF;
    font-size: 18px;
    font-weight: 700;

  }
  .bonus { 
    color: #C6E7FF;
    font-size: 12px;
   }
  .reward { 
    color: #C6E7FF;
    font-weight: 400;
  }
  .takeBonus {
    min-height: 22px;
    background: #1D343B;
    border-radius: 10px;
    padding: 2px 8px;
    color: #C6E7FF;
    font-size: 0.8rem;
    font-weight: 800;
    box-shadow: 0px 0px 5px #000000;
    display: flex;
    align-items: center;
    margin-right: 5%;
  
   }

   .modalOverlay {
    padding: 20px;
  
    background-color: #0000002f;
  
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    .modal {
      position: fixed;
      margin: auto;
      padding: 0 20px 10px;
      flex-direction: column;
      width: calc(100% - 40px);
      border-radius: 25px;
      background: radial-gradient(51.44% 28.81% at 49.09% 115.08%, rgb(59, 178, 229) -20%, rgb(24, 32, 38) 90%), rgb(28, 56, 74);
      min-height: 180px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .closeBtn {
        position: absolute;
        right: 9px;
        top: 3.5px;
  
        background: none;
        border: none;
  
        cursor: pointer;
      }
      
      .text{
        text-align: center;
        font-weight: 100;
        color: #C6E7FF;
    }
      .modalTopBar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
  
        // background: radial-gradient(
        //     51.44% 100.81% at 50.09% -25.08%,
        //     rgb(59, 178, 229) -20%,
        //     rgb(24, 32, 38) 90%
        //   ),
        //   rgba(28, 56, 74);
  
        .modalTitle {
          font-weight: 700;
          font-size: 20px;
          line-height: 85%;
          letter-spacing: -0.03em;
          text-align: center;
          color: #c6e7ff;
        }
      }
  
      .modalContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
  
        .values {
          display: flex;
          flex-direction: column;
          gap: 2px;
          width: 130%;
        }
  
        .fieldBlock {
          display: flex;
          flex-direction: column;
          gap: 2px;
          width: 100%;
  
          margin-top: 4px;
        }
  
        .labelBlock {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .label {
          display: flex;
          align-items: center;
          gap: 2px;
          padding: 0 16px;
  
          font-weight: 300;
          font-size: 14px;
          line-height: 121%;
          letter-spacing: -0.04em;
          text-align: center;
          color: #c6e7ff;
        }
  
        .availability {
          display: flex;
          gap: 2px;
          padding: 0 16px;
  
          font-weight: 300;
          font-size: 10px;
          line-height: 1;
          letter-spacing: -0.05em;
          text-align: center;
          color: #c6e7ff;
          align-items: flex-end;
  
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 1;
            letter-spacing: -0.04em;
            text-align: center;
            color: #c6e7ff;
          }
        }
  
        .correlation {
          display: flex;
          justify-content: space-between;
          gap: 23px;
  
          width: 100%;
        }
  
        .amount,
        .price,
        .gasFee,
        .total {
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 42px 0 rgba(57, 208, 241, 0.5);
  
          height: 32px;
          width: 100%;
          border: 0.5px solid #3bb2e5;
          border-radius: 25px;
  
          background: rgba(11, 12, 13, 0.3);
  
          font-weight: 400;
          font-size: 14px;
          line-height: 121%;
          letter-spacing: -0.04em;
          text-align: center;
          color: #c6e7ff;
        }
  
        .confirmButton {
          display: flex;
          justify-content: center;
          align-items: center;
  
          width: 159px;
          height: 30px;
  
          font-weight: 400;
          font-size: 18px;
          line-height: 94%;
          letter-spacing: -0.03em;
          text-align: center;
          color: #c6e7ff;
          background: rgba(11, 12, 13, 0.3);
  
          border: 1px solid #339dd9;
          border-radius: 25px;
          border: 1px solid #182025;
          border-radius: 25px;
          // box-shadow: 0 0 5px 0 #bbddf3;
          outline: 1px solid #339dd9;
          overflow: hidden;
  
          cursor: pointer;
        }
      }
    }
  }
