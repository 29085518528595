#ton-connect-button {
  z-index: 9999;
  button {
    background: transparent !important;
    color: transparent !important;
  }

  div {
    background: transparent !important;
    color: transparent !important;
  }
  
svg {
  display: none;
}

}
