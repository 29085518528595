* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.block {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 11px;

  width: 100%;

  border: 1px solid #3bb2e5;
  border-radius: 25px;

  background-color: rgba(28, 56, 74, 0.66);

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .orderForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1px;

      font-weight: 400;
      font-size: 16px;
      line-height: 106%;
      letter-spacing: -0.03em;
      text-align: center;
      color: #c6e7ff;

      width: 100%;

      .commingSoon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: calc(100% - 52px);
        height: 108px;

        border: 1px solid #3bb2e5;
        border-radius: 25px;
        background: radial-gradient(
            91.44% 41.19% at 49.09% 95.08%,
            rgb(32, 97, 124) 0%,
            rgba(17, 33, 40, 0.32) 100%
          ),
          radial-gradient(
            5.59% 44.61% at -15.19% 102.54%,
            rgb(41, 123, 158) 0%,
            rgba(17, 33, 40, 0.32) 100%
          ),
          rgba(28, 56, 74, 0.66);

        box-shadow: 0 0 4px 0 rgba(0, 209, 255, 0.45),
          0 4px 4px 0 rgba(59, 178, 229, 0.25);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 28px;
        line-height: 53%;
        letter-spacing: -0.02em;
        text-align: center;
        color: #c6e7ff;
      }

      input {
        position: relative;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        z-index: 5;

        font-weight: 400;
        font-size: 16px;
        line-height: 106%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #c6e7ff;
        &::placeholder {
          color: #c6e7ff;
          opacity: 1;
        }
      }

      .textInput {
        position: relative;

        border: 0.5px solid #3bb2e5;
        border-radius: 25px;
        width: 281px;
        height: 32px;

        overflow: hidden;

        &:focus {
          box-shadow: 0 0 12px 0 #39d0f1, 0 0 4px 0 rgba(0, 209, 255, 0.45);
        }

        svg {
          position: absolute;
          width: 100%;
          height: 100%;
          margin: auto;
          left: 0;
          top: 0;
          z-index: 1;
        }
      }

      .price {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #c6e7ff;
        opacity: 1;

        .gasFee {
          position: relative;
          width: 281px;
          z-index: 10;
          text-align: right;
          padding-right: 12px;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: -0.5px;

          span {
            font-weight: 300;
            font-size: 9px;
            line-height: 1;
            letter-spacing: -0.06em;
            text-transform: lowercase;

            color: rgba(198, 231, 255, 0.6);
            z-index: 10;
          }
        }

        svg {
          position: absolute;
          width: 100%;

          margin: auto;
          left: 0;
          top: 0;
          z-index: 1;
        }
      }

      .orderButton {
        width: 118px;
        height: 30px;

        margin-top: 15px;

        font-weight: 400;
        font-size: 18px;
        line-height: 94%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #ffc6c6;
        background: #491d1d;

        border: 1px solid #ff2727;
        border-radius: 25px;
      }
    }
  }

  .topBar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 100%;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;
      height: 40px;
      color: #c6e7ff;

      z-index: 20;
    }

    .value {
      font-size: 23px;
      font-weight: 700;
      color: #fff;
      z-index: 20;
    }

    .backgroundTrapezoid {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}