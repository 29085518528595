* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;

  color: #c6e7ff;

  z-index: 20;
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #3bb2e5;
  border-radius: 25px;
  background-color: rgba(28, 56, 74, 0.66);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 5px;
  height: 100%;
  max-height: 700px;
  height: 89vh;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    border: 1px solid #3bb2e5;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    background-color: rgba(28, 56, 74, 0.66);
    box-shadow: 0px 5px 4px 0 rgba(0, 0, 0, 0.51);
    border-bottom: none;
    z-index: 100;

    .message {
      font-weight: 700;
      font-size: 24px;
      line-height: 125%;
      letter-spacing: -0.02em;
      text-align: center;
      color: #c6e7ff;
      width: 266px;
    }
  }

  .topBar {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    z-index: 100;

    .title {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;

      color: #c6e7ff;

      z-index: 20;
    }

    .value {
      font-size: 23px;
      font-weight: 700;
      color: #fff;
      z-index: 20;
    }

    .backgroundTrapezoid {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.refferal{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #c6e7ff;
}

.profitPersent{
  color: #C6E7FF;
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 0.6rem;
  .icon{
    width: 0.6rem;
    height: 0.6rem;
  }
}
.headerContainer:nth-child(even) {
  background-color: rgba(17, 33, 40, 0.7);
}

.headerContainer:nth-child(odd) {
  background-color: rgba(101, 184, 220, 0.32);
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  color: #C6E7FF;
  font-weight: 600;
  font-size: 1rem;
  height: 100%;
  .subtext {
    font-size: 0.6rem;
  }
}

.headerContainer:first-child {
  border-top-left-radius: 25px;
  width: 40%;

}

.headerContainer:last-child {
  border-top-right-radius: 25px;
}


.referrals{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.referralRow{
  display: flex;
  width: 100%;
  flex-direction: row;
  .icon{
    width: 1rem;
  }
}
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  gap: 5%;
}
.referralCell{
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #C6E7FF;
  justify-content: center;
}
.referralCell:nth-child(even) {
  background-color: rgba(17, 33, 40, 0.7);
}

.referralCell:nth-child(odd) {
  background-color: rgba(101, 184, 220, 0.32);
}
.referralCell:first-child {
  border-left: 1px solid #3bb2e5;
  width: 40%;
  flex-direction: row;
  justify-content: flex-start;

}
.referralCell:last-child {
  border-right: 1px solid #3bb2e5;
}
.referralRow:last-child{

  .referralCell:first-child {
    border-bottom-left-radius: 25px;

  }
  .referralCell:last-child {
    border-bottom-right-radius: 25px;

  }
}
.separ{
  background: linear-gradient(90deg, rgba(59, 178, 229, 0) 0%, #3BB2E5 30%, #3BB2E5 70%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 1px;
}
.d_flex{
  display: flex;
  flex-direction: column;
}