* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.soldOutBack{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(111, 111, 111, 0.20) 0%, rgba(213, 213, 213, 0.27) 100%);
    z-index: 100;
    border-radius: 16px;
}

.card {
  position: relative;

  display: flex;
  justify-content: space-between;

  flex-shrink: 0;

  gap: 11px;

  width: 100%;
  height: 90px;

  border-radius: 16px;
  border: 1px solid #3be5a8;
  box-shadow: 0 0 12px 0 #3be5a8, 0 0 4px 0 rgba(0, 255, 76, 0.45);

  background: radial-gradient(
    135.59% 144.61% at -15.19% 102.54%,
    #227b4a 0%,
    #182025 100%
  );

  .info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2px;

    padding: 5px 0;

    width: 100%;
    height: 100%;

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 106%;
      letter-spacing: -0.03em;
      text-align: left;
      color: #c7ffc6;
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      width: 100%;
      height: 100%;

      .descriptionItem {
        font-weight: 500;
        font-size: 12px;

        letter-spacing: -0.04em;
        text-align: left;
        color: rgb(234, 255, 234);

        .value {
          font-weight: 700;
          font-size: 15px;

          letter-spacing: -0.03em;
        }

        .unit {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.06em;
        }
      }
    }
  }

  .priceBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 12px;

    position: absolute;
    right: 6px;
    bottom: 6px;

    .price {
      position: relative;
      font-weight: 700;
      font-size: 26px;
      line-height: 65%;
      letter-spacing: -0.02em;
      text-align: center;
      color: #fff;

      .discount {
        position: absolute;
        top: -20px;
        left: -16px;

        font-weight: 700;
        font-size: 14px;
        letter-spacing: -0.04em;
        text-align: center;
        color: #fff;

        &::after {
          position: absolute;
          content: "";
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          border-radius: 2px;
          height: 2px;
          background-color: #c94040;
          z-index: 9;
        }
      }
    }
  }

  .image {
    position: relative;
    height: auto;
    width: 100px;
    border-radius: 15px;
    background-color: #3be5a8;
    overflow: hidden;

    flex-shrink: 0;

    .stick {
      display: flex;
      justify-content: center;
      align-items: start;

      height: 15px;

      font-weight: 700;
      font-size: 12px;
      line-height: 1;
      letter-spacing: -0.04em;
      text-align: center;
      color: #fff;
      text-transform: capitalize;

      position: absolute;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.21) 20%, #1dd925 100%);

      bottom: 0;
      width: 100%;
      border-radius: 21%;
      margin: 0 auto;

      z-index: 10;
    }

    img {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 12;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      
    }
  }
}
