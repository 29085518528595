* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.block {
  position: relative;
  overflow: hidden;

  padding: 0;

  height: 333px;
  width: 100%;

  border-radius: 25px;
  border: 1px solid #3bb2e5;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;

  .backBtn {
    position: absolute;
    left: 9px;
    top: 7px;
    z-index: 3;
  }

  .bgImage {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    img {
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0.25;
      background: radial-gradient(
        58.49% 58.49% at 58.49% 50%,
        #12a5f9 41%,
        #3bb2e5 100%
      );
    }
  }

  .topBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 63px;
    width: 100%;

    .title {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;

      color: #c6e7ff;

      z-index: 20;
    }

    .value {
      font-size: 23px;
      font-weight: 700;
      color: #fff;
      z-index: 20;
    }

    .backgroundTrapezoid {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }

  .bottomBar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;

    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    width: 100%;
  }
}
