.modalOverlay {
  padding: 20px;

  background-color: #0000002f;

  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    position: fixed;
    margin: auto;
    padding: 0 20px 10px;
    flex-direction: column;
    width: calc(100% - 40px);
    border-radius: 25px;
    background: radial-gradient(
        51.44% 28.81% at 49.09% 115.08%,
        rgb(59, 178, 229) -20%,
        rgb(24, 32, 38) 90%
      ),
      rgba(28, 56, 74);
      min-height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
    .closeBtn {
      position: absolute;
      right: 9px;
      top: 3.5px;

      background: none;
      border: none;

      cursor: pointer;
    }

    .modalTopBar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      // background: radial-gradient(
      //     51.44% 100.81% at 50.09% -25.08%,
      //     rgb(59, 178, 229) -20%,
      //     rgb(24, 32, 38) 90%
      //   ),
      //   rgba(28, 56, 74);

      .modalTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 85%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #c6e7ff;
      }
    }

    .modalContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .values {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 130%;
      }

      .fieldBlock {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;

        margin-top: 4px;
      }

      .labelBlock {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .label {
        display: flex;
        align-items: center;
        gap: 2px;
        padding: 0 16px;

        font-weight: 300;
        font-size: 14px;
        line-height: 121%;
        letter-spacing: -0.04em;
        text-align: center;
        color: #c6e7ff;
      }

      .availability {
        display: flex;
        gap: 2px;
        padding: 0 16px;

        font-weight: 300;
        font-size: 10px;
        line-height: 1;
        letter-spacing: -0.05em;
        text-align: center;
        color: #c6e7ff;
        align-items: flex-end;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 1;
          letter-spacing: -0.04em;
          text-align: center;
          color: #c6e7ff;
        }
      }

      .correlation {
        display: flex;
        justify-content: space-between;
        gap: 23px;

        width: 100%;
      }

      .amount,
      .price,
      .gasFee,
      .total {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 42px 0 rgba(57, 208, 241, 0.5);

        height: 32px;
        width: 100%;
        border: 0.5px solid #3bb2e5;
        border-radius: 25px;

        background: rgba(11, 12, 13, 0.3);

        font-weight: 400;
        font-size: 14px;
        line-height: 121%;
        letter-spacing: -0.04em;
        text-align: center;
        color: #c6e7ff;
      }

      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 159px;
        height: 30px;

        font-weight: 400;
        font-size: 18px;
        line-height: 94%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #c6e7ff;
        background: rgba(11, 12, 13, 0.3);

        border: 1px solid #339dd9;
        border-radius: 25px;
        border: 1px solid #182025;
        border-radius: 25px;
        // box-shadow: 0 0 5px 0 #bbddf3;
        outline: 1px solid #339dd9;
        overflow: hidden;

        cursor: pointer;
      }
    }
  }
}
.walletBtn {
 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 159px;
  height: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 94%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #c6e7ff;
  background: rgba(11, 12, 13, 0.3);
  border: 1px solid #182025;
  border-radius: 25px;
  outline: 1px solid #339dd9;
  overflow: hidden;
  cursor: pointer;
  .customBorder {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow: hidden;
  }

  #ton-connect-button button {
    background: transparent !important;
    color: transparent !important;
    display: none;
}
  

}


.errorContainer{
  display: flex;
  flex-direction: column;
}
.warning{
    color: white;
    font-size: 0.7rem;
    margin-top: 10px;
    font-weight: 100;
}