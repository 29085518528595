* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.title {
  font-weight: 700;
  font-size: 18px;

  letter-spacing: -0.03em;
  text-align: center;
  color: #c7ffc6;

  z-index: 20;
}

.block {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  height: 100%;

  border: 1px solid #3be5a8;
  border-radius: 25px;

  background: radial-gradient(
    81.44% 28.81% at 49.09% -5.08%,
    #0b9d69 0%,
    rgb(24, 32, 38) 100%
  );

  box-shadow: 0 0 12px 0 #3be5a8, 0 0 4px 0 rgba(0, 209, 255, 0.45);

  .backBtn {
    position: absolute;
    left: 9px;
    top: 7px;
    z-index: 3;
  }

  .walletBtn {
    position: absolute;
    right: 9px;
    top: 7px;
    z-index: 3;
  }

  .topBar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 100%;

    .title {
      font-weight: 700;
      font-size: 18px;

      letter-spacing: -0.03em;
      text-align: center;
      color: #c7ffc6;

      z-index: 20;
    }

    .value {
      font-size: 23px;
      font-weight: 700;
      color: #fff;
      z-index: 20;
    }

    .backgroundTrapezoid {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 222px;

      .stick {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        right: 26px;

        font-weight: 700;
        font-size: 14px;
        line-height: 121%;
        letter-spacing: 0.07em;
        text-align: center;
        color: #fff;
        transform: rotate(15deg);
        transform-origin: center;

        border-radius: 100%;
        background-color: #d00202;

        height: 28px;
        width: 28px;
        z-index: 5;

        &::before {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          content: "";
          height: 32px;
          width: 32px;
          border: 2px solid #d00202;
          border-radius: 100%;

          margin: auto;
          z-index: -1;
        }
      }
    }
  }
}
