* {
  box-sizing: border-box;
}

.plusButton,
.removeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
}

.backBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #1c384a;

  width: 60px;
  height: 30px;

  border: 1px solid #182025;
  border-radius: 25px;
  // box-shadow: 0 0 5px 0 #bbddf3;
  outline: 1px solid #339dd9;
  overflow: hidden;

  cursor: pointer;

  .customBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #bbddf3;
    border: 1px solid #1c384a;
    border-radius: 25px;

    overflow: hidden;
  }
}

.walletBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #1c384a;

  width: 60px;
  height: 30px;

  border: 1px solid #182025;
  border-radius: 25px;
  box-shadow: 0 0 5px 0 #3be5a8;
  outline: 1px solid #c7ffc6;
  overflow: hidden;

  cursor: pointer;

  .customBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #c7ffc6;
    border: 1px solid #1c384a;
    border-radius: 25px;
    padding: 10px;
    overflow: hidden;
  }
}

.tonBalance {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #1c384a;

  width: 60px;
  height: 30px;

  border: 1px solid #182025;
  border-radius: 25px;
  // box-shadow: 0 0 5px 0 #bbddf3;
  outline: 1px solid #339dd9;
  overflow: hidden;

  cursor: pointer;

  .customBorder {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 2px;

    overflow: hidden;

    span {
      font-weight: 700;
      font-size: 13px;

      letter-spacing: -0.04em;
      text-align: center;
      color: #c6e7ff;
    }
  }
}

.repairBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 20px;

  letter-spacing: -0.03em;
  text-align: center;

  border-radius: 16px;
  border: 1px solid #182025;
  outline: 1px solid #3bb2e5;
  padding: 7px 10px 8px 10px;
  width: 154px;
  height: 41px;

  background-color: #1c384a;
  color: #fff;

  cursor: pointer;
}

.copyLink {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #1c384a;

  width: 135px;
  height: 35px;

  border: 1px solid #182025;
  border-radius: 25px;
  // box-shadow: 0 0 5px 0 #bbddf3;
  outline: 1px solid #339dd9;
  overflow: hidden;

  cursor: pointer;

  .customBorder {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 6px;

    overflow: hidden;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 106%;
      letter-spacing: -0.03em;
      text-align: center;
      color: #c6e7ff;
    }
  }
}
