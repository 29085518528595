* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.card {
  display: flex;
  justify-content: space-between;

  flex-shrink: 0;

  gap: 15px;
  padding: 5px 6px 5px 6px;

  width: 100%;
  height: 90px;

  border-radius: 16px;
  border: 1px solid #39d0f1;
  box-shadow: 0 0 12px 0 #39d0f1, 0 0 4px 0 rgba(0, 209, 255, 0.45);

  background: radial-gradient(
    135.59% 144.61% at -15.19% 102.54%,
    #226b7b 0%,
    #182025 100%
  );
  .info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    height: 100%;

    .imageBg {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      border-radius: 15px;
      background-color: #348db3;
      overflow: hidden;

      img {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 0.75;
        background: radial-gradient(
          58.49% 58.49% at 58.49% 50%,
          #1e5166 41%,
          #1e5166 100%
        );
      }
    }

    .title {
      font-weight: 400;
      font-size: 16px;

      letter-spacing: -0.03em;
      text-align: center;
      color: #c6e7ff;

      z-index: 5;
    }

    .description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      height: 100%;

      z-index: 5;

      .fromValue {
        font-weight: 400;
        font-size: 24px;
        line-height: 71%;
        letter-spacing: -0.02em;
        text-align: center;
        color: #c6e7ff;
      }

      .toValue,
      .name {
        font-weight: 400;
        font-size: 24px;
        line-height: 71%;
        letter-spacing: -0.02em;
        text-align: center;
        color: #c6e7ff;
      }
    }
  }

  .image {
    position: relative;
    height: auto;
    width: 80px;
    border-radius: 15px;
    background-color: #348db3;
    overflow: hidden;

    flex-shrink: 0;

    img {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 12;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background: radial-gradient(
        58.49% 58.49% at 58.49% 50%,
        #12a5f9 41%,
        #3bb2e5 100%
      );
    }
  }
}
