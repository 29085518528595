
.energizen {
    width: 100vw;
    height: 100vh;
    background-image: url(https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Backgr/Energizer.webp);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.info_container {
    border-radius: 20px;
    background-color: #1C384A;
    display: flex;
    color: #BBDDF3;
    align-items: center;
    gap: 5px;
    padding: 5px;
}
.block {
    display: flex;
    flex-direction: row;
    background: #182025;
    align-items: center;
    border-radius: 20px;
    gap: 10px;
    justify-content: space-between;
    border: 1px solid #339DD9;

}

.block-miner{
    border: 1px solid #3BE5A8;
    border-radius: 20px;

    .text{
        color: #BBF3DF;
    }
    .info_container{
        background: #1C4A47;
    }
    
}


.block-investor{
    
    border: 1px solid #E0C745;
    border-radius: 20px;

    .text{
        color: #FFF9C6;
    }
    .info_container{
        background: #4A451C;
    }

}



.block_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.current_count_cotainer{
    display: flex;
    flex-direction: row;
    background: #182025;
    padding: 6px 5px;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #339DD9;
    justify-content: center;
}
.current_count{
    color: white;
    font-size: 20px;
    font-family: Ubuntu;
    font-weight: 700;
    line-height: 17px;
    word-wrap: break-word;
    background: #1C384A;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    padding: 6px;
}


.cliker_container {
    border-radius: 25px;
    background: radial-gradient(135.59% 135.59% at 50% 102.54%, rgba(59, 178, 229, 0.7) 0%, rgba(24, 32, 37, 0) 100%), radial-gradient(81.44% 28.81% at 49.09% -5.08%, #3BB2E5 0%, rgba(24, 32, 38, 0) 100%), #182025;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-height: 45%;
    .img_clicker{
        width: 90%;
        margin: 15px 0;
        overflow: scroll;
        object-fit: cover;
        border-radius: 20px;
    }
    
    .header_island_container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    
        .text{
            color: #C6E7FF;
            text-align: center;
            font-family: Ubuntu;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: -0.5px;
            z-index: 1;

        }
        img{
            position: absolute;
            
        }
    }
}

.img_navigation{
    position: fixed;
    z-index: -1;
}

.cerrent_info_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.progress_info_con {
    display: flex;
    flex-direction: column;
    background: #182025;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #339DD9;
    justify-content: space-between;
    padding: 10px;
}
.termonuclear{
    color: var(--text-color-1, #BBDDF3);
    text-align: center;
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.5px;
    background: #1C384A;
    width: 80%;
    border-top-right-radius: 10px;
    padding: 7px 5px;
    border-top-left-radius: 10px;
}
.level{
    text-align: center;
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.5px;
    background: #1C384A;
    border-top-right-radius: 10px;
    padding: 7px 5px;
    border-top-left-radius: 10px;
    color: #FFD37D;
}

.status-widget {
    background: #1C384A;
    width: 100%;
    display: flex;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.status-item {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
}

.status-item:last-child {
    margin-bottom: 0;
}

.label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

.progress-bar {
    background: #222;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    height: 20px;
    width: 50%;
}

.progress {
    height: 20px;
    background: linear-gradient(to right, #47D4DD, #66B5A7);
    border-radius: 15px;
}

.progress-bar.wear .progress {
    background: linear-gradient(to right, #AE4F4F, #AE4F4F    );
}
.label {
    background: linear-gradient(270deg, #141A1F 0%, rgba(20, 26, 31, 0) 100%);
    font-family: Ubuntu;
    font-weight: 500;
    padding: 5px 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 20%;
}
.percentage {
    background: linear-gradient(90deg, #141A1F 0%, rgba(20, 26, 31, 0.00) 100%);
    color: #D7FFD7;
    text-align: center;
    font-family: Ubuntu;
    font-weight: 500;
    padding: 5px 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 20%;
}
.status {
    background: #1B252E;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 5px solid #1C384A;
    border-radius: 15px;
    padding: 10px 0;
}
.footer_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 10px !important;
}
.FooterBtn {
    background: #252318;
    border-radius: 20px 20px 0 0;
    border: 1px solid #339DD9;
    border-bottom: none !important;
    padding: 10px 10px 0 10px;
    width: 22%;
    position: relative;
    z-index: 10;

    .text_container_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-between;
        position: relative;
    }

    .text {
        background: #1C384A;
        width: 100%;
        border-radius: 10px 10px 0 0;
        padding: 5px 5px 0 5px;
        text-align: center;
        color: rgba(187, 221, 243, 0.8);
        font-weight: 500;
        font-size: 0.8rem;
    }
    
    img{margin-bottom: 10px;}

}

.income-animation {
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.5s ease-out;
    font-size: 20px;
    font-weight: 700;
    z-index: 1000; 
    position: absolute;
}

.FooterBtn-miner {
    
    border: 1px solid rgba(59, 229, 168, 1);
    
    .text {
       color: #BBF3E2;
       background: #1C4A47;;

    }
    
}
.FooterBtn-investor {
    
    border: 1px solid #E5D43B;
    
    .text {
       color: #FFF6C6;
       background: #4A431C;;

    }
    
}
.img_clicker {
    transition: transform 10ms ease-in-out;
}

.img_clicker.scale {
    transform: scale(0.98);
}
@media screen and (max-height: 780px) {
    .img_clicker{
        width: 90%;
        margin: 15px 0;
        overflow: scroll;
        object-fit: cover;
        border-radius: 20px;
        height: auto !important;
    }
    .cliker_container {
        border-radius: 25px;
        background: radial-gradient(135.59% 135.59% at 50% 102.54%, rgba(59, 178, 229, 0.7) 0%, rgba(24, 32, 37, 0) 100%), radial-gradient(81.44% 28.81% at 49.09% -5.08%, #3BB2E5 0%, rgba(24, 32, 38, 0) 100%), #182025;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 35%;
    }
}
.current_balance{
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    color: white;
    font-weight: 500;
}