* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;

  color: #c6e7ff;

  z-index: 20;
}

.block {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;

  border: 1px solid #3bb2e5;
  border-radius: 25px;

  background: radial-gradient(
    81.44% 28.81% at 49.09% -5.08%,
    rgb(59, 178, 229) 0%,
    rgb(24, 32, 38) 100%
  );

  box-shadow: 0 0 12px 0 #39d0f1, 0 0 4px 0 rgba(0, 209, 255, 0.45);

  .topBar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 100%;

    .title {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;

      color: #c6e7ff;

      z-index: 20;
    }

    .value {
      font-size: 23px;
      font-weight: 700;
      color: #fff;
      z-index: 20;
    }

    .backgroundTrapezoid {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
