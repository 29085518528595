.block{
    display: flex;
    width: 100%;
    justify-content: space-around;
    background: rgba(24, 32, 37, 0.5);
    border: 1px solid #3BB2E5;
    border-radius: 40px;
    margin-bottom: 10px;
}
.parametr{
    color: #C6E7FF;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #1c282ec2;
    font-size: 1.2rem;
    border: 1px solid #3BB2E5;
    border-radius: 22px;
    margin: 5px;
    font-weight: 600;
    padding: 3px;
    align-items: center;
}
.selected{
    box-shadow: inset 0px 0px 20px 0px #3bb2e5a3;
    background: #182025;
}
