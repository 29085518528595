.investor {
    width: 100vw;
    height: 100vh;
    background-image: url("https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Backgr/Investor.webp");
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.coming_soon{
    border: 1px solid #F3FE71;
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    color: #FFF9C6;
    font-weight: 700;
    font-size: 1.4rem;
    background: radial-gradient(#0000004f, #6e6817b8);
    font-size: 3vh;
}