.container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
  flex-wrap: wrap;
  padding-bottom: 90px;
}
.linkAsics{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #eaffea;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.5px;
    background: #182025;
    border: 1px solid transparent;
    border-radius: 10px;
    outline: 1px solid #3be5a8;
    overflow: hidden;
    cursor: pointer;
    z-index: 0;
    width: 100%;
    padding: 5px 0;
}