* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;
  height: 40px;
  color: #c6e7ff;

  z-index: 20;
}



.active{
  width: 70px !important;
}
.containerFilter{
  display: flex;
  width: 50%;
  justify-content: flex-end;
  gap: 5%;
}
.tradeButton{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  transition: width 500ms;
}
.buy{
  color: #C6FFEA;  
  outline: 1px solid #3be5c6;
  border: 1px solid #182025;
  background: #1d4349;
}
.sell{
  color: #ffc6c6;
  background: #491d1d;
  border: 1px solid #ff2727;
}


.toggleButtons{
  display: flex;
  gap: 5px;
  border: 0.5px solid #3bb2e5;
  border-radius: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 106%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #c6e7ff;
  background: rgba(24, 32, 37, 0.8);
  padding: 0 5px; 
   min-height: 34px;
  align-items: center;
}


.block {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 7px;

  width: 100%;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .orderForm {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 7px;

      font-weight: 400;
      font-size: 16px;
      line-height: 106%;
      letter-spacing: -0.03em;
      text-align: center;
      color: #c6e7ff;
      width: 100%;

      .range {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border: 1px solid #3bb2e5;
        border-radius: 25px;

        background-color: rgba(28, 56, 74, 0.66);

        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

        .textInput {
          border: none;
        }
        .separator {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      input {
        z-index: 5;
        border: 0.5px solid #3bb2e5;
        border-radius: 25px;
        height: 34px;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 106%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #c6e7ff;

        background: radial-gradient(
            91.44% 41.19% at 49.09% 95.08%,
            rgb(32, 97, 124) 0%,
            rgba(17, 33, 40, 0.32) 100%
          ),
          radial-gradient(
            5.59% 44.61% at -15.19% 102.54%,
            rgb(41, 123, 158) 0%,
            rgba(17, 33, 40, 0.32) 100%
          ),
          rgba(28, 56, 74, 0.66);

        &::placeholder {
          color: #c6e7ff;
          opacity: 1;
        }
      }

      .preSort{
        background: rgba(24, 32, 37, 0.8);
        border-radius: 50%;
        border: 1px solid #339DD9;
        font-size: 12px;
        width: 34px;
        height: 34px;
        flex-shrink: 0;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        // background-image: url("./500.png");
      }
      .textInput {
        position: relative;

        border: 0.5px solid #3bb2e5;
        border-radius: 25px;

        height: 34px;

        overflow: hidden;
      }

      .orderBy {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 100%;
        width: 45%;

        .orderByBtn {
          border: 0.5px solid #3bb2e5;
          border-radius: 25px;

          height: 34px;
          width: 100%;

          font-weight: 400;
          font-size: 16px;
          line-height: 106%;
          letter-spacing: -0.03em;
          text-align: center;
          color: #c6e7ff;

          background: radial-gradient(
              91.44% 41.19% at 49.09% 95.08%,
              rgb(32, 97, 124) 0%,
              rgba(17, 33, 40, 0.32) 100%
            ),
            radial-gradient(
              5.59% 44.61% at -15.19% 102.54%,
              rgb(41, 123, 158) 0%,
              rgba(17, 33, 40, 0.32) 100%
            ),
            rgba(28, 56, 74, 0.66);
        }
      }

      .sortButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 34px;
        height: 34px;

        flex-shrink: 0;

        padding: 2px;

        font-weight: 400;
        font-size: 18px;
        line-height: 94%;
        letter-spacing: -0.03em;
        text-align: center;
        color: #ffc6c6;
        background: radial-gradient(
            91.44% 41.19% at 49.09% 95.08%,
            rgb(32, 97, 124) 0%,
            rgba(17, 33, 40, 0.32) 100%
          ),
          radial-gradient(
            5.59% 44.61% at -15.19% 102.54%,
            rgb(41, 123, 158) 0%,
            rgba(17, 33, 40, 0.32) 100%
          ),
          rgba(28, 56, 74, 0.66);

        border: 1px solid #3bb2e5;
        border-radius: 100%;

        cursor: pointer;

        .asc,
        .desc {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10px;
          height: 13px;
        }
      }
    }
  }
}
.selected{
  color: transparent !important;
}