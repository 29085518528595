.cardList {
  display: flex;
  flex-direction: column;
  gap: 14px;

  padding-bottom: 11px;
  padding-left: 9px;
  padding-right: 9px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 311px;
  width: 100%;
}
