* {
  box-sizing: border-box;
}

.miner {
  width: 100vw;
  height: 100vh;
  background-image: url("https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Backgr/Miner.webp");
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;   
   overflow: hidden; /* Prevent scrolling on the main container */
  
  
}

.container {
  padding: 5px 10px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */

}

.footer {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
}
